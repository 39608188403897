import { defineAction } from '_utils/redux'
import * as getCardsService from '_services/card'

export const GET_CARDS = defineAction('GET_CARDS')
export const CHANGE_STATUS_ACTIVE_CARD = defineAction('CHANGE_STATUS_ACTIVE_CARD')

export const getCards = () => (dispatch, getState) =>
  dispatch({
    type: GET_CARDS.ACTION,
    payload: getCardsService.getCards(getState().authentication.get('accessToken')),
  })

export const changeStatusActiveCard = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: CHANGE_STATUS_ACTIVE_CARD.ACTION,
    payload: getCardsService.changeStatusActiveCard(
      getState().authentication.get('accessToken'),
      id,
      payload
    ),
  })
